<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formatDate(value)"
        :label="labelOrDefault"
        readonly
        v-on="on"
        :disabled="disabled"
        autofocus
      ></v-text-field>
    </template>
    <v-date-picker :value="getFormattedDate('YYYY-MM-DD')" @input="handleDateChange"></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

import formatMixin from '@/mixins/formatMixin';
export default {
  name: 'Date',
  data: () => ({
    dateMenu: false
  }),

  mixins: [formatMixin],
  props: ['value', 'disabled', 'label'],
  computed: {
    labelOrDefault() {
      return this.label ? this.label : 'Date';
    } /*,

    pickerDate() {
        :picker-date.sync="pickerDate"

      return moment().format('YYYY-MM');
  }*/
  },
  methods: {
    getFormattedDate(format) {
      if (this.value) {
        return moment(this.value).format(format);
      }
    },

    handleDateChange(value) {
      if (typeof value === 'string') value = moment(value).toDate();
      this.dateMenu = false;

      this.$emit('input', value);
    }
  }
};
</script>

<style></style>
