export enum ResolutionNames {
  QVGA = 'QVGA',
  VGA = 'VGA',
  HD = 'HD',
  FULLHD = 'FULLHD',
  FOURK = 'FOURK',
  EIGHTK = 'EIGHTK',
  CUSTOM = 'CUSTOM'
}

export const Resolutions = new Map([
  [
    ResolutionNames.QVGA,
    {
      width: '320',
      height: '240'
    }
  ],
  [
    ResolutionNames.VGA,
    {
      width: '640',
      height: '480'
    }
  ],
  [
    ResolutionNames.HD,
    {
      width: '1280',
      height: '720'
    }
  ],
  [
    ResolutionNames.FULLHD,
    {
      width: '1920',
      height: '1080'
    }
  ],
  [
    ResolutionNames.FOURK,
    {
      width: '4096',
      height: '2160'
    }
  ],
  [
    ResolutionNames.EIGHTK,
    {
      width: '7680',
      height: '4320'
    }
  ],
  [
    ResolutionNames.CUSTOM,
    {
      width: '1024',
      height: '1024'
    }
  ]
]);
