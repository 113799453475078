import Document from '@/models/Document/Document';
import moment from 'moment';
import Vue from 'vue';
export default Vue.extend({
  methods: {
    getimeWithoutSeconds(time: string) {
      if (time) {
        const s = time.split(':');
        return `${s[0]}:${s[1]}`;
      } else {
        return '';
      }
    },
    formatDate(date: string) {
      if (date) {
        return moment(date).format('DD/MMM/YY');
      }
    },

    parseDate(date: number): string {
      if (date > 0) {
        return moment.utc(date).format('DD/MMM/YY');
      } else {
        return '';
      }
    },
    parseDateTime(date: number): string {
      if (date > 0) {
        return moment(date).format('DD/MMM/YY HH:mm');
      } else {
        return '';
      }
    },

    getTotal(document: Document): string {
      return '$' + this.getTotalFloat(document);
    },

    getTotalFloat(document: Document, isTotalFocused?: boolean): string {
      const total = typeof document.total == 'undefined' ? 0 : parseFloat(document.total.split(',').join(''));
      //const perUnit = parseFloat(document.perUnit.split(',').join(''));
      //const units = parseFloat(document.units.split(',').join(''));
      //const total = units * perUnit;
      let result = isTotalFocused ? '' : '0.00';
      if (!isNaN(total)) {
        result = total.toLocaleString('en-CA', {
          minimumFractionDigits: 2
        });
      }
      return result;
    },

    getAmount(document: Document): string {
      return '$' + this.getAmountFloat(document);
    },

    getAmountFloat(document: Document, isAmountFocused?: boolean): string {
      const amount = typeof document.amount == 'undefined' ? 0 : parseFloat(document.amount.split(',').join(''));
      //const perUnit = parseFloat(document.perUnit.split(',').join(''));
      //const units = parseFloat(document.units.split(',').join(''));
      //const total = units * perUnit;
      let result = isAmountFocused ? '' : '0.00';
      if (!isNaN(amount)) {
        result = amount.toLocaleString('en-CA', {
          minimumFractionDigits: 2
        });
      }
      return result;
    },

    toLocalStringWith2Digits(number: string) {
      if (number) {
        return parseFloat(number).toLocaleString('en-CA', { minimumFractionDigits: 2 });
      }
      return '0';
    }
  }
});
